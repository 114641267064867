import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login"),
    // hidden: true,
  },
  {
    path: "/",
    name: "index",
    meta: {
      level: 1,
    },
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/sbu",
    name: "sbu",
    meta: {
      level: 2,
    },
    component: () => import("@/views/sbu.vue"),
  },
  {
    path: "/sau",
    name: "sau",
    meta: {
      level: 2,
    },
    component: () => import("@/views/sau.vue"),
  },
  {
    path: "/img",
    name: "img",
    meta: {
      level: 2,
    },
    component: () => import("@/views/img.vue"),
  },
  {
    path: "/projectWork/:projectId",
    name: "projectWork",
    meta: {
      level: 3,
    },
    component: () => import("@/views/projectWork.vue"),
  },
  {
    path: "/culture",
    name: "culture",
    meta: {
      level: 2,
    },
    component: () => import("@/views/culture.vue"),
  },

  {
    path: "/step/one/:userId/:nickName",
    name: "one",
    meta: {
      level: 2,
    },
    component: () => import("@/views/step/one.vue"),
  },
  {
    path: "/step/two/:userId/:nickName",
    name: "two",
    meta: {
      level: 3,
    },
    component: () => import("@/views/step/two.vue"),
  },
  {
    path: "/step/three/:userId/:nickName",
    name: "three",
    meta: {
      level: 4,
    },
    component: () => import("@/views/step/three.vue"),
  },
  {
    path: "/step/four/:userId/:nickName",
    name: "four",
    meta: {
      level: 5,
    },
    component: () => import("@/views/step/four.vue"),
  },
  {
    path: "/step/five/:userId/:nickName",
    name: "five",
    meta: {
      level: 6,
    },
    component: () => import("@/views/step/five.vue"),
  },
  {
    path: "/files/:userId",
    name: "files",
    meta: {
      level: 10,
    },
    component: () => import("@/views/files.vue"),
  },
  {
    path: "/mind/:userId",
    name: "mind",
    meta: {
      level: 10,
    },
    component: () => import("@/views/mind.vue"),
  },
  {
    path: "/job/:deptId",
    name: "job",
    meta: {
      level: 12,
    },
    component: () => import("@/views/job.vue"),
  }, {
    path: "/jobCollect",
    name: "jobCollect",
    meta: {
      level: 15,
    },
    component: () => import("@/views/jobCollect.vue"),
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
